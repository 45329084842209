import React from 'react';
import '../Page/Page.scss';
import './Projects.scss';
import Card from '../../UI/Card/Card';
import { projectsData } from '../../../data/ProjectsData';
import TagsList from '../../UI/TagsList/TagsList';
import VideoJS from '../../VideoJS/VideoJS';

const Projects = () => {
  const defaultOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    poster: `${process.env.PUBLIC_URL}/HorseRacingSim.PNG`,
    sources: [
      {
        src: `${process.env.PUBLIC_URL}/videos/CS50FinalProjectOverview.mp4`,
        type: 'video/mp4',
      },
    ],
    disablePictureInPicture: true,
    controlBar: {
      playToggle: true,
      currentTimeDisplay: true,
      durationDisplay: true,
      remainingTimeDisplay: false,
      fullscreenToggle: true,
      pictureInPictureToggle: false,
      children: [
        'playToggle',
        'volumePanel',
        'currentTimeDisplay',
        'progressControl',
        'durationDisplay',
        'fullscreenToggle',
      ],
      volumePanel: {
        inline: false,
      },
    },
  };

  return (
    <section className="page projects">
      <section>
        {projectsData &&
          projectsData.map(d => {
            return (
              <Card key={d.id} classes="node">
                <h3>{d.title}</h3>
                <VideoJS options={{ ...defaultOptions, ...d.videoOptions }} />
                <p>{d.summary}</p>
                {(d.projectLink || d.repositoryLink) && <h5>Links</h5>}
                {d.projectLink && (
                  <TagsList
                    classes="link"
                    tags={['Project']}
                    link={d.projectLink}
                  />
                )}
                {d.repositoryLink && (
                  <TagsList
                    classes="link"
                    tags={['Repository']}
                    link={d.repositoryLink}
                  />
                )}
                <h5>Skills</h5>
                <TagsList classes="skills" tags={d.tags} />
              </Card>
            );
          })}
      </section>
    </section>
  );
};

export default Projects;
