export const resumeData = {
  name: 'Zackery Leaman',
  title: 'Front-End Developer',
  email: 'zleaman3@gmail.com',
  phone: '214-901-9063',
  location: 'Dallas, TX',
  linkedIn: 'https://www.linkedin.com/in/zack-leaman-165b30b7/',
  github: 'https://github.com/ZackLeaman',
  careerObjective:
    'Industry professional with over 7 years of experience working in the software engineering and front-end web development fields. Created and maintained gamified educational technology that continues to service millions of students. Has a deep passion for learning and growing as a developer, with a focus on efficient code and delightful user experiences.',
  workExperience: [
    {
      id: '0',
      job: 'Front-End Developer',
      company: 'Istation',
      time: 'Jul 2022 - Current',
      location: 'Dallas, TX',
      points: [
        'Aided in company transition and exploration of web-based applications.',
        'Continue to work on an educational quiz front end Angular web app that fetches Airtable activity data and surfaces content to the end user. The performance, analytics, and reward data is then saved to the back end before the user replays the current activity or continues to the next.',
        'Built a Video Angular Component that extended Video.js to surface HLS with custom features such as closed caption controls, live transcription, and full scrubbing behaviors within user’s total watched time.',
      ],
    },
    {
      id: '1',
      job: 'Software Developer',
      company: 'Istation',
      time: 'Feb 2017 - Jul 2022',
      location: 'Dallas, TX',
      points: [
        'Worked in proprietary engine, runtime, and language based on Java to build front end content for Istation app.',
        'Built Math K-2 educational content for 100,000+ users on Istation app.',
        'Redesigned and rebuilt Istation app home page for millions of active users under Project Power Path.',
        'Designed and built Istation app Student Assessment Score Dashboard for millions of active users under Project Power Path for Reading, Español, and Math products.',
      ],
    },
  ],
  education: [
    {
      id: '0',
      school: 'The University of Texas at Dallas',
      degree: "Bachelor's Degree, Arts and Technology",
      time: '2014 - 2016',
      gpa: 'GPA 3.919',
      points: ['Summa Cum Laude', 'Phi Theta Kappa'],
    },
    {
      id: '1',
      school: 'Collin College',
      degree: "Associate's Degree, Science",
      time: '2012 - 2014',
      gpa: 'GPA 4.0',
      points: ['Phi Theta Kappa'],
    },
  ],
  certifications: [
    {
      id: '0',
      title: 'React - The Complete Guide',
      subTitle: '50 Hour Online Course from Maximilian Schwarzmüller',
      time: '2024',
      link: 'https://www.udemy.com/course/react-the-complete-guide-incl-redux/',
      inProgress: true,
    },
    {
      id: '1',
      title: 'NodeJS - The Complete Guide',
      subTitle: 'Over 40 Hour Online Course from Maximilian Schwarzmüller',
      time: '2024',
      link: 'https://www.udemy.com/certificate/UC-48db556d-07d0-4ac5-8d38-6e2f08629709/',
    },
    {
      id: '2',
      title: 'Angular - The Complete Guide',
      subTitle: 'Over 34 Hour Online Course from Maximilian Schwarzmüller',
      time: '2022',
      link: 'https://www.udemy.com/certificate/UC-cfc8e7bb-e027-43fb-8e9c-47ab29faffc2/',
    },
    {
      id: '3',
      title: "CS50's Introduction to Computer Science",
      subTitle: 'Course of study offered by HarvardX',
      time: '2021',
      link: 'https://courses.edx.org/certificates/9e11e5f018db442d868ad20b9d645007',
    },
  ],
  skills: [
    'Angular',
    'React',
    'Node.js',
    'Express.js',
    'Typescript',
    'JavaScript',
    'HTML / CSS / SASS',
    'Jasmine / Karma',
    'Cypress',
    'Sessions / Cookies / Auth',
    'SQL / NoSQL',
    'MongoDB / Mongoose',
    'REST API',
    'GraphQL',
    'Git / GitHub',
    'CI / CD',
    'EJS',
    'Java',
    'C# (Unity)',
  ],
};
