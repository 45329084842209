import React from 'react';
import '../Page/Page.scss';
import './Expertise.scss';
import Card from '../../UI/Card/Card';
import { expertiseData } from '../../../data/ExpertiseData';
import InfoGridModal from '../../UI/InfoGridModal/InfoGridModal';

const Expertise = () => {
  return (
    <section className="page expertise">
      <section>
        {expertiseData &&
          expertiseData.map(d => {
            return (
              <Card key={d.id} classes="node">
                <InfoGridModal id={d.id} title={d.title} data={d.data} />
              </Card>
            );
          })}
      </section>
    </section>
  );
};

export default Expertise;
