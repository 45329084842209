import React from 'react';
import './ButtonLink.scss';

const ButtonLink = props => {
  return (
    <a
      className="button-link"
      href={props.url}
      target="_blank"
      rel="noreferrer"
    >
      {props.icon}
    </a>
  );
};

export default ButtonLink;
