import React from 'react';
import Banner from './Banner/Banner';
import './Header.scss';
import NavigationBar from './NavigationBar/NavigationBar';

const Header = ({ navBarItems, activePage, setActivePage }) => {
  const pageClickHandler = page => {
    if (navBarItems.includes(page)) {
      setActivePage(page);
    }
  };

  return (
    <header>
      <Banner></Banner>
      <NavigationBar
        navBarItems={navBarItems}
        activeItem={activePage}
        onItemClick={pageClickHandler}
      ></NavigationBar>
    </header>
  );
};

export default Header;
