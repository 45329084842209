import React, { useEffect, useState } from 'react';
import '../Page/Page.scss';
import styles from './Contact.module.scss';
import Card from '../../UI/Card/Card';
import emailjs from '@emailjs/browser';

const freshFormValue = () => {
  return {
    firstname: { value: '', valid: false, touched: false },
    lastname: { value: '', valid: false, touched: false },
    email: { value: '', valid: false, touched: false },
    phone: { value: '', valid: true, touched: false },
    message: { value: '', valid: false, touched: false },
    _isValid: false,
  };
};

const validateNotEmpty = val =>
  val ? val.replaceAll(' ', '').length > 0 : false;

const validateEmail = email => {
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const validatePhone = phone => {
  if (phone && phone.replaceAll(' ', '').length > 0) {
    return phone.match(
      /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
    );
  }

  return true;
};

const evaluateEntryValidity = (id, value) => {
  if (id === 'email') {
    return validateEmail(value);
  } else if (id === 'phone') {
    return validatePhone(value);
  }
  return validateNotEmpty(value);
};

const FormStateEnum = {
  INPUT: 0,
  SUBMITTING: 1,
  SUBMITTED: 2,
};

const Contact = () => {
  const [formValue, setFormValue] = useState(freshFormValue());
  const [formState, setFormState] = useState(FormStateEnum.INPUT);

  // debounce validity
  useEffect(() => {
    const identifier = setTimeout(() => {
      const newValue = { ...formValue, _isValid: true };
      let validityChange = false;
      for (const [key, value] of Object.entries(formValue)) {
        if (key === '_isValid') {
          continue;
        }
        const isValid = evaluateEntryValidity(key, value.value);
        if (isValid !== value.valid) {
          validityChange = true;
          newValue[key].valid = isValid;
        }
        if (!isValid) {
          newValue._isValid = false;
        }
      }
      if (validityChange) {
        setFormValue(newValue);
      }
    }, 500);

    return () => {
      clearTimeout(identifier);
    };
  }, [formValue]);

  // handle submitting form
  useEffect(() => {
    switch (formState) {
      case FormStateEnum.SUBMITTING:
        emailjs
          .send(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            {
              first_name: formValue.firstname.value,
              last_name: formValue.lastname.value,
              email: formValue.email.value,
              phone: formValue.phone.value,
              message: formValue.message.value,
            },
            process.env.REACT_APP_PUBLIC_KEY
          )
          .then(() => {
            setTimeout(() => {
              return Promise.resolve();
            }, 4000);
          })
          .then(() => {
            setFormState(FormStateEnum.SUBMITTED);
          })
          .catch(err => console.error(err));
        break;
      case FormStateEnum.SUBMITTED:
        setTimeout(() => {
          setFormState(FormStateEnum.INPUT);
        }, 4000);
        break;
      default:
        setFormValue(freshFormValue());
        break;
    }
  }, [formState]);

  const onBlur = id => {
    setFormValue(current => {
      const newValue = { ...current };
      if (id === 'email') {
        newValue[id].valid = validateEmail(newValue[id].value);
      } else if (id === 'phone') {
        newValue[id].valid = validatePhone(newValue[id].value);
      } else {
        newValue[id].valid = validateNotEmpty(newValue[id].value);
      }
      newValue[id].touched = true;
      return newValue;
    });
  };

  const onChange = (id, value) => {
    setFormValue(current => {
      const newValue = { ...current, _isValid: false };
      newValue[id].value = value;
      return newValue;
    });
  };

  const sendEmail = e => {
    e.preventDefault();
    if (formValue._isValid) {
      setFormState(FormStateEnum.SUBMITTING);
    }
  };

  return (
    <section className="page contact">
      <section>
        <Card classes="node">
          <h2>Contact Me!</h2>
          <form onSubmit={sendEmail} className={styles['contact-form']}>
            <div className={styles.flex}>
              <div className={styles['half-container']}>
                <label htmlFor="first_name">First name:</label>
                <br></br>
                <input
                  type="text"
                  className={
                    !formValue.firstname.valid && formValue.firstname.touched
                      ? styles.invalid
                      : ''
                  }
                  id="first_name"
                  name="first_name"
                  required
                  value={formValue.firstname.value}
                  onChange={e => onChange('firstname', e.target.value)}
                  onBlur={() => onBlur('firstname')}
                  disabled={formState !== FormStateEnum.INPUT}
                />
              </div>
              <div
                className={`${styles['half-container']} ${styles['no-margin']}`}
              >
                <label htmlFor="last_name">Last name:</label>
                <br></br>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  required
                  value={formValue.lastname.value}
                  className={
                    !formValue.lastname.valid && formValue.lastname.touched
                      ? styles.invalid
                      : ''
                  }
                  onChange={e => onChange('lastname', e.target.value)}
                  onBlur={() => onBlur('lastname')}
                  disabled={formState !== FormStateEnum.INPUT}
                />
              </div>
              <div className={styles['half-container']}>
                <label htmlFor="email">Email:</label>
                <br></br>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={formValue.email.value}
                  className={
                    !formValue.email.valid && formValue.email.touched
                      ? styles.invalid
                      : ''
                  }
                  onChange={e => onChange('email', e.target.value)}
                  onBlur={() => onBlur('email')}
                  disabled={formState !== FormStateEnum.INPUT}
                />
              </div>
              <div
                className={`${styles['half-container']} ${styles['no-margin']}`}
              >
                <label htmlFor="phone">Phone (optional):</label>
                <br></br>
                <input
                  type="phone"
                  id="phone"
                  name="phone"
                  value={formValue.phone.value}
                  className={
                    !formValue.phone.valid && formValue.phone.touched
                      ? styles.invalid
                      : ''
                  }
                  onChange={e => onChange('phone', e.target.value)}
                  onBlur={() => onBlur('phone')}
                  disabled={formState !== FormStateEnum.INPUT}
                />
              </div>
              <div className={styles['full-container']}>
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  cols="50"
                  required
                  value={formValue.message.value}
                  className={
                    !formValue.message.valid && formValue.message.touched
                      ? styles.invalid
                      : ''
                  }
                  onChange={e => onChange('message', e.target.value)}
                  onBlur={() => onBlur('message')}
                  disabled={formState !== FormStateEnum.INPUT}
                ></textarea>
              </div>
              {formState === FormStateEnum.INPUT && (
                <button
                  type="submit"
                  className={`${styles.submit} ${styles['full-container']}`}
                  disabled={!formValue._isValid}
                >
                  Submit
                </button>
              )}
              {formState === FormStateEnum.SUBMITTING && (
                <div
                  className={`${styles['full-container']} ${styles.submitting}`}
                >
                  <p>Submitting</p>
                  <p>.</p>
                  <p>.</p>
                  <p>.</p>
                </div>
              )}
              {formState === FormStateEnum.SUBMITTED && (
                <div
                  className={`${styles['full-container']} ${styles.submitted}`}
                >
                  <p>Submitted!</p>
                </div>
              )}
            </div>
          </form>
        </Card>
      </section>
    </section>
  );
};

export default Contact;
