import React, { useEffect, useState } from 'react';
import './NavigationBar.scss';
import NavigationBarButton from './NavigationBarButton/NavigationBarButton';
import IconHamburgerMenu from './Icons/IconHamburgerMenu';
import IconChevronCompactDown from './Icons/IconChevronCompactDown';

const NavigationBar = props => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [open, setOpen] = useState(false);
  const isConstricted = dimensions.width <= 640;

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
      setOpen(false);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const onClick = title => {
    setOpen(false);
    props.onItemClick(title);
  };

  const toggleNavBar = () => {
    setOpen(state => !state);
  };

  return (
    <div className="nav-bar-container">
      {isConstricted && (
        <div className="nav-bar-toggle-container">
          <button className="nav-bar-toggle-btn" onClick={toggleNavBar}>
            <span>
              {`${props.activeItem} `}
              {open ? <IconChevronCompactDown /> : <IconHamburgerMenu />}
            </span>
          </button>
        </div>
      )}
      {(!isConstricted || open) && (
        <div className="nav-bar-buttons-container">
          <div className={!isConstricted ? 'nav-bar' : 'nav-bar-constricted'}>
            {props.navBarItems.map(item => (
              <NavigationBarButton
                key={item}
                onClick={onClick}
                title={item}
                classes={item === props.activeItem ? 'active' : ''}
              ></NavigationBarButton>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationBar;
