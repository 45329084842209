export const expertiseData = [
  {
    id: '2',
    title: 'Skills',
    data: [
      {
        id: '0',
        icon: `${process.env.PUBLIC_URL}/icons/angular.svg`,
        alt: 'Angular Icon',
        title: 'Angular',
        startYear: 2022,
        tags: [
          'TypeScript',
          'JavaScript',
          'HTML',
          'CSS',
          'SASS',
          'Jasmine',
          'Karma',
          'Git',
          'GitHub',
        ],
      },
      {
        id: '1',
        icon: `${process.env.PUBLIC_URL}/icons/react.png`,
        alt: 'React Icon',
        title: 'React',
        startYear: 2023,
        tags: ['JavaScript', 'HTML', 'CSS', 'SASS', 'Git', 'GitHub'],
      },
      {
        id: '2',
        icon: `${process.env.PUBLIC_URL}/icons/node-js.svg`,
        alt: 'Node.js Icon',
        title: 'Node.js',
        startYear: 2024,
        tags: [
          'MVC',
          'Express.js',
          'REST API',
          'GraphQL',
          'SQL',
          'NoSQL',
          'MongoDB',
          'Socket.io',
          'Authentication',
          'JavaScript',
          'Typescript',
        ],
      },
      {
        id: '3',
        icon: `${process.env.PUBLIC_URL}/icons/istation.jpg`,
        alt: 'Istation Icon',
        title: "Istation's Proprietary Software",
        startYear: 2017,
        tags: [
          'Java',
          'TortoiseSVN',
          'TestRail',
          'O.O. Programming',
          'Event-Driven Architecture',
        ],
      },
    ],
  },
  {
    id: '1',
    title: 'Certifications',
    data: [
      {
        id: '0',
        icon: `${process.env.PUBLIC_URL}/icons/edx.jpg`,
        alt: 'edX Icon',
        title: "CS50's Introduction to Computer Science",
        subTitle:
          'Course of study offered by HarvardX, an online learning initiative of Harvard University',
        endYear: 2021,
        link: 'https://courses.edx.org/certificates/9e11e5f018db442d868ad20b9d645007',
        tags: ['Completed Certificate'],
      },
      {
        id: '1',
        icon: `${process.env.PUBLIC_URL}/icons/udemy.svg`,
        alt: 'Udemy Icon',
        title: 'Angular - The Complete Guide',
        subTitle: 'Udemy Online Course of over 34 total hours',
        endYear: 2022,
        link: 'https://www.udemy.com/certificate/UC-cfc8e7bb-e027-43fb-8e9c-47ab29faffc2/',
        tags: ['Completed Certificate'],
      },
      {
        id: '2',
        icon: `${process.env.PUBLIC_URL}/icons/udemy.svg`,
        alt: 'Udemy Icon',
        title: 'React - The Complete Guide',
        subTitle: 'Udemy Online Course of over 50 total hours',
        endYear: 2024,
        link: 'https://www.udemy.com/course/react-the-complete-guide-incl-redux/',
        tags: ['In Progress'],
      },
      {
        id: '3',
        icon: `${process.env.PUBLIC_URL}/icons/udemy.svg`,
        alt: 'Udemy Icon',
        title: 'Node.JS - The Complete Guide',
        subTitle: 'Udemy Online Course of over 40 total hours',
        endYear: 2024,
        link: 'https://www.udemy.com/certificate/UC-48db556d-07d0-4ac5-8d38-6e2f08629709/',
        tags: ['Completed Certificate'],
      },
    ],
  },
  {
    id: '0',
    title: 'Education',
    data: [
      {
        id: '0',
        icon: `${process.env.PUBLIC_URL}/icons/UTD.png`,
        alt: 'The University of Texas at Dallas Icon',
        title: 'The University of Texas at Dallas',
        subTitle: "Bachelor's Degree, Arts and Technology",
        startYear: 2014,
        endYear: 2016,
        tags: ['3.919 GPA', 'Summa Cum Laude', 'Phi Theta Kappa'],
      },
      {
        id: '1',
        icon: `${process.env.PUBLIC_URL}/icons/CollinCollege.png`,
        title: 'Collin College',
        subTitle: "Associate's Degree, Science",
        startYear: 2012,
        endYear: 2014,
        tags: ['4.0 GPA', 'Phi Theta Kappa'],
      },
    ],
  },
];
