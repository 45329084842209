import React from 'react';
import './Footer.scss';
import ButtonLink from './ButtonLink/ButtonLink';
import IconLinkedin from './ButtonLink/Icons/IconLinkedIn';
import IconGmail from './ButtonLink/Icons/IconGmail';

const Footer = () => {
  const buttonLinks = [
    {
      title: 'LinkedIn',
      url: 'https://www.linkedin.com/in/zack-leaman-165b30b7/',
      icon: <IconLinkedin />,
    },
    {
      title: 'Gmail',
      url: 'https://mail.google.com/mail/?view=cm&source=mailto&to=zleaman3@gmail.com',
      icon: <IconGmail />,
    },
  ];

  return (
    <footer>
      <div className="contact-info-container">
        <span>Based in Dallas, Texas</span>
        <span>zleaman3@gmail.com</span>
      </div>
      <div className="button-links">
        {/* <IconLinkedin/>
        <IconGmail/> */}
        {buttonLinks.map(link => (
          <ButtonLink
            key={link.title}
            title={link.title}
            url={link.url}
            icon={link.icon}
          />
        ))}
      </div>
    </footer>
  );
};

export default Footer;
