import React from 'react';
import styles from './IconSpan.module.scss';

const IconSpan = ({ imgSrc, altText, text, link, classes }) => {
  return (
    <span className={`${styles['icon-span']} ${classes}`}>
      {imgSrc && (
        <img src={`${process.env.PUBLIC_URL}/icons/${imgSrc}`} alt={altText} />
      )}
      {!link ? (
        <p>{text}</p>
      ) : (
        <a href={link} target="_blank" rel="noreferrer">
          {text}
        </a>
      )}
    </span>
  );
};

export default IconSpan;
