import React, { useState } from 'react';
import './App.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Pages/Home/Home';
import Expertise from './components/Pages/Expertise/Expertise';
import Projects from './components/Pages/Projects/Projects';
import Resume from './components/Pages/Resume/Resume';
import Contact from './components/Pages/Contact/Contact';

const App = () => {
  const navBarItems = ['Home', 'Expertise', 'Projects', 'Resume', 'Contact'];
  const [activePage, setActivePage] = useState(navBarItems[0]);

  console.log('App Start');

  return (
    <div className="App">
      <div className="bg">
        <div className="bg-1" />
        {/* <div className="bg-2" />
        <div className="bg-3" />
        <div className="sun" /> */}
      </div>
      <Header
        navBarItems={navBarItems}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      <div className="artist">Image by Susan Cipriano from Pixabay</div>
      {activePage === 'Home' && <Home />}
      {activePage === 'Expertise' && <Expertise />}
      {activePage === 'Projects' && <Projects />}
      {activePage === 'Resume' && <Resume />}
      {activePage === 'Contact' && <Contact />}
      <Footer />
    </div>
  );
};

export default App;
