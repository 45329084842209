import React from 'react';
import '../Page/Page.scss';
import './Home.scss';
import Card from '../../UI/Card/Card';
import Modal from '../../UI/Modal/Modal';
import { homeData } from '../../../data/HomeData';

const Home = () => {
  return (
    <section className="page home">
      <section>
        {homeData &&
          homeData.map(d => {
            return (
              <Card key={d.id} classes="node">
                <Modal
                  src={d.src ? `${process.env.PUBLIC_URL}${d.src}` : ''}
                  title={d.title ?? ''}
                  text={d.text ?? ''}
                  imageFirst={d.imageFirst}
                  lottieSrc={
                    d.lottieSrc ? `${process.env.PUBLIC_URL}${d.lottieSrc}` : ''
                  }
                />
              </Card>
            );
          })}
      </section>
    </section>
  );
};

export default Home;
