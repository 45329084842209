import React from 'react';
import styles from './Modal.module.scss';
import LottiePlayer from '../LottiePlayer/LottiePlayer';

const Modal = props => {
  let iconEl;
  if (props.src) {
    iconEl = (
      <img className={styles.image} src={props.src} alt={props.alt ?? ''} />
    );
  }

  return (
    <section className={styles.container}>
      {props.imageFirst && props.src && iconEl}
      {props.imageFirst && props.lottieSrc && (
        <div className={styles['lottie-container']}>
          <LottiePlayer src={props.lottieSrc} />
        </div>
      )}
      {(props.title || props.text) && (
        <div className={styles.content}>
          {props.title && <h3 className={styles.title}>{props.title}</h3>}
          {props.text && <p className={styles.text}>{props.text}</p>}
        </div>
      )}
      {!props.imageFirst && props.src && iconEl}
      {!props.imageFirst && props.lottieSrc && (
        <div className={styles['lottie-container']}>
          <LottiePlayer src={props.lottieSrc} />
        </div>
      )}
    </section>
  );
};

export default Modal;
