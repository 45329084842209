import React from 'react';
import './InfoGridModal.scss';
import TagsList from '../TagsList/TagsList';

const InfoGridModal = ({ id, title, data }) => {
  return (
    <section>
      <h3 className="section-title" key={id}>
        {title}
      </h3>
      {data && (
        <ol className="entries">
          {data
            .sort((a, b) => {
              if (a.startYear && b.startYear) {
                return b.startYear - a.startYear;
              } else if (a.endYear && b.endYear) {
                return b.endYear - a.endYear;
              }
              return 0;
            })
            .map(d => (
              <li className={`entry ${!d.subTitle ? 'no-sub' : ''}`} key={d.id}>
                <img className="icon" src={d.icon} alt={d.alt} />
                <h4 className="entry-title">{d.title}</h4>
                <p className="entry-subtitle">{d.subTitle}</p>
                {d.startYear && d.endYear && (
                  <p className="date">{`${d.startYear} - ${d.endYear}`}</p>
                )}
                {d.startYear && !d.endYear && (
                  <p className="date">{`${d.startYear} - Present`}</p>
                )}
                {!d.startYear && d.endYear && (
                  <p className="date">{d.endYear}</p>
                )}
                {d.tags && (
                  <TagsList classes="tags" tags={d.tags} link={d.link} />
                )}
              </li>
            ))}
        </ol>
      )}
    </section>
  );
};

export default InfoGridModal;
