import React, { useCallback, useEffect, useState } from 'react';
import styles from './LottiePlayer.module.scss';
import Lottie from 'react-lottie-wrapper';

const LottiePlayer = props => {
  const [lottieDefaultOptions, setLottieDefaultOptions] = useState(null);
  const [isPaused, setIsPaused] = useState(false);

  const fetchLottieHandler = useCallback(async () => {
    const lottieAnimData = await fetch(props.src, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      return response.json();
    });

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: lottieAnimData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    setLottieDefaultOptions(defaultOptions);
  }, []);

  useEffect(() => {
    if (props.src) {
      fetchLottieHandler();
    }
  }, [fetchLottieHandler]);

  const togglePlayState = () => {
    setIsPaused(paused => !paused);
  };

  return (
    <button
      className={`${styles['lottie-container']} ${
        isPaused ? styles.paused : styles.playing
      }`}
      onClick={togglePlayState}
      aria-pressed={isPaused}
      aria-label={isPaused ? 'play animation' : 'pause animation'}
    >
      {lottieDefaultOptions && (
        <Lottie
          options={lottieDefaultOptions}
          isStopped={false}
          isPaused={isPaused}
          isClickToPauseDisabled={true}
          tabIndex={-1}
        />
      )}
      <img
        src={
          isPaused
            ? `${process.env.PUBLIC_URL}/icons/play.svg`
            : `${process.env.PUBLIC_URL}/icons/pause.svg`
        }
        alt={isPaused ? 'play icon' : 'pause icon'}
      />
    </button>
  );
};

export default LottiePlayer;
