import React, { useRef } from 'react';
import Card from '../../UI/Card/Card';
import { resumeData } from '../../../data/ResumeData';
// import styles from './Resume.module.scss';
import IconSpan from '../../UI/IconSpan/IconSpan';
import ReactToPrint from 'react-to-print';

const Resume = () => {
  const resumeSecRef = useRef(null);

  return (
    <section className={`page resume`}>
      <section ref={resumeSecRef}>
        {resumeData && (
          <Card classes="node resume">
            <section className="header">
              <h2>{resumeData.name}</h2>
              <h3>{resumeData.title}</h3>
              <div className="grid-3-col">
                <IconSpan
                  text={resumeData.email}
                  imgSrc="email.svg"
                  altText="email icon"
                />
                <IconSpan
                  text={resumeData.phone}
                  imgSrc="mobile.svg"
                  altText="phone number icon"
                />
                <IconSpan
                  text={resumeData.location}
                  imgSrc="location.svg"
                  altText="location icon"
                />
                <IconSpan
                  text={resumeData.github}
                  link={resumeData.github}
                  imgSrc="github.svg"
                  altText="GitHub icon"
                />
                <IconSpan
                  text={resumeData.linkedIn}
                  link={resumeData.linkedIn}
                  imgSrc="linkedin.svg"
                  altText="LinkedIn icon"
                  classes="grid-col-2-to-4"
                />
              </div>
            </section>
            <section className="body">
              <div className="main">
                <section className="work-exp">
                  <h3>Work Experience</h3>
                  {resumeData.workExperience &&
                    resumeData.workExperience.map(exp => (
                      <section className="entry" key={exp.id}>
                        <h4>{exp.job}</h4>
                        <h5>{exp.company}</h5>
                        <div className="work-exp-entry-subinfo grid-2-col">
                          <IconSpan
                            text={exp.time}
                            imgSrc="calendar.svg"
                            altText="calendar icon"
                          />
                          <IconSpan
                            text={exp.location}
                            imgSrc="location.svg"
                            altText="location icon"
                          />
                        </div>
                        {exp.points && (
                          <ul className="points">
                            {exp.points.map((p, i) => (
                              <li key={i}>{p}</li>
                            ))}
                          </ul>
                        )}
                      </section>
                    ))}
                </section>
                <section className="certifications">
                  <h3>Certifications</h3>
                  {resumeData.certifications &&
                    resumeData.certifications.map(cert => (
                      <section className="entry" key={cert.id}>
                        <h4>{cert.title}</h4>
                        <h5>{cert.subTitle}</h5>
                        <div className="grid-2-col">
                          <IconSpan
                            text={
                              !cert.inProgress
                                ? 'Certificate Available'
                                : 'In Progress Course'
                            }
                            link={cert.link}
                            imgSrc="link.svg"
                            altText="link icon"
                          />
                          <IconSpan
                            text={cert.time}
                            imgSrc="calendar.svg"
                            altText="calendar icon"
                          />
                        </div>
                      </section>
                    ))}
                </section>
              </div>
              <div className="side">
                <section className="career-obj">
                  <h3>Career Objective</h3>
                  <p>{resumeData.careerObjective}</p>
                </section>
                <section className="education">
                  <h3>Education</h3>
                  {resumeData.education &&
                    resumeData.education.map(ed => (
                      <section className="entry" key={ed.id}>
                        <h4>{ed.degree}</h4>
                        <h5>{ed.school}</h5>
                        <div className="grid-2-col">
                          <IconSpan
                            text={ed.time}
                            imgSrc="calendar.svg"
                            altText="calendar icon"
                          />
                          <IconSpan
                            text={ed.gpa}
                            imgSrc="grade.svg"
                            altText="G.P.A. icon"
                          />
                        </div>
                        {ed.points && (
                          <ul className="points">
                            {ed.points.map((p, i) => (
                              <li key={i}>{p}</li>
                            ))}
                          </ul>
                        )}
                      </section>
                    ))}
                </section>
                <section className="skills">
                  <h3>Skills</h3>
                  {resumeData.skills && (
                    <ul>
                      {resumeData.skills.map((s, i) => (
                        <li key={i}>{s}</li>
                      ))}
                    </ul>
                  )}
                </section>
              </div>
            </section>
          </Card>
        )}
      </section>
      <ReactToPrint
        trigger={() => (
          <button className="print-button">
            <img
              src={`${process.env.PUBLIC_URL}/icons/print.svg`}
              alt="print"
            />
            Print
          </button>
        )}
        content={() => resumeSecRef.current}
        copyStyles={true}
        bodyClass="print"
      />
    </section>
  );
};

export default Resume;
