export const homeData = [
  {
    id: '0',
    src: '/PortraitPhoto.PNG',
    title: 'Front-End Web Developer',
    text: 'Industry professional with over 7 years of experience working in the software engineering and front-end web development fields. Created and maintained gamified educational technology that continues to service millions of students. Has a deep passion for learning and growing as a developer, with a focus on efficient code and delightful user experiences.',
  },
  {
    id: '1',
    imageFirst: true,
    lottieSrc: '/responsive.json',
    title: 'Responsive Design',
    text: 'Mobile-first responsive design gives developers confidence that their work will adapt to the user instead of vise versa. With minimal effort, one can work from the smallest screen resolution and add key breakpoints along the way that mold the design for best viewing. This is backed with key properties such as Flexbox and Grid that can be used to resize and reorder elements with little amount of CSS.',
  },
  {
    id: '2',
    lottieSrc: '/improve-rating.json',
    title: 'Accessibility',
    text: 'It is important to always look to the WCAG standards and follow their four principles: perceivable, operable, understandable, and robust. This comes into action with concepts such as tab-targeting, prefers-reduced-motion, aria-labels, aria-roles, and more. Picking the correct HTML element for the job that is being performed is also needed. From tools such as screen readers to preventive steps such as color contrast passes, developers have a duty to provide a web content foundation for all users.',
  },
  {
    id: '3',
    imageFirst: true,
    lottieSrc: '/corgi.json',
    title: 'Enhanced Experiences',
    text: 'An intuitive interaction is difficult to capture, but it can come from analytics, user feedback and user testing. Peer review is also imperative for the refinement process, usually through a Git manager such as GitHub. Once refined, functionality can be further protected through unit and automated testing. One step further with a linter and formatter, and anyone joining development can stay within the bounds set by the project owner. These processes are never truly complete, and are consistently refined to provide the user with a rewarding and memorable experience.',
  },
];
