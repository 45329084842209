import React from 'react';
import styles from './TagsList.module.scss';

const TagsList = ({ tags, link, classes }) => {
  return (
    <ul className={`${styles.tags} ${classes}`}>
      {tags.map(tag => {
        return (
          <li
            key={tag}
            className={`${
              tag === 'Completed Certificate' ? styles.completed : ''
            } ${tag === 'In Progress' ? styles['in-progress'] : ''} ${
              link ? styles.link : ''
            }`}
          >
            {!link && <span>{tag}</span>}
            {link && (
              <a href={link} target="_blank" rel="noreferrer" tabIndex={0}>
                <span>
                  {tag}
                  <img
                    tabIndex={-1}
                    src={`${process.env.PUBLIC_URL}/icons/link.svg`}
                    alt={'link icon'}
                  />
                </span>
              </a>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default TagsList;
