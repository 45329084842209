import React from 'react';
import './NavigationBarButton.scss';

const NavigationBarButton = props => {
  const onClick = () => {
    props.onClick(props.title);
  };

  return (
    <button className={`nav-bar-button ${props.classes}`} onClick={onClick}>
      {props.title}
    </button>
  );
};

export default NavigationBarButton;
